<template>
    <div class="modal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="header_status">
                        <div class="d-flex statusTotal presuccess text-center" v-if="issue.access_sb == 'agreed' && issue.access_chief == 'agreed' && issue.status !== 'issued'">
                            <fa class="icon pe-1 pt-1 me-2" icon="thumbs-up"></fa>
                            ВЫДАЧА РАЗРЕШЕНА
                        </div>
                        <div class="d-flex statusTotal success text-center" v-if="issue.status === 'issued'">
                            <fa class="icon pe-1 pt-1 me-2" icon="handshake"></fa>
                            АВТОМОБИЛЬ ВЫДАН
                        </div>
                        <div class="d-flex statusTotal stop text-center" v-if="issue.access_sb != 'agreed' || issue.access_chief != 'agreed'">
                            <fa class="icon pe-1 pt-1 me-2" icon="hand"></fa>
                            ВЫДАЧА ЗАПРЕЩЕНА
                        </div>
                    </div>
                    <button type="button" class="btn-close close_modal" @click="close"></button>
                </div>
                <div class="modal-body">
                    
                    <!--DESCRIPTION-->
                    <div class="description col-12 d-flex vstack">
                        

                        <div class="col-12 d-flex hstack mt-2">
                            <!-- time -->
                            <div class="col-4 time hstack h5">
                                <fa class="icon me-2 text-black-50 opacity-50" icon="clock"></fa>
                                <span class="tm d-block">{{ issue.time[0] }}<br />{{ issue.time[1] }}</span>
                            </div>
                            <!-- car -->
                            <div class="car col-8 d-flex vstack ">
                                <span><fa class="icon me-2 pt-1 text-black-50 opacity-50" icon="car"></fa><strong>{{ issue.info.car }}</strong></span>
                                <span class="vin"><fa class="icon me-2 pt-1 text-black-50 opacity-50" icon="barcode"></fa>{{ issue.info.vin }}</span>
                                <span class="vin"><fa class="icon me-2 pt-1 text-black-50 opacity-50" icon="file-lines"></fa><span class="small mr-1">Договор: </span>{{ issue.info.contract_num }}</span>
                            </div>                        
                        </div>

                        <!-- client & manager -->
                        <div class="client_manager col-12 d-flex vstack mt-3">
                            <div class="client col-12">
                                <fa class="icon me-2 pt-1 text-black-50 opacity-50" icon="user"></fa> {{ issue.info.client }}
                            </div>
                            <div class="manager col-12">
                                <fa class="icon me-2 pt-1 text-black-50 opacity-50" icon="user-tie"></fa> {{ issue.user.surname }} {{ issue.user.name }}
                            </div>
                        </div>

                        <!-- buttons -->
                        <div class="btnsBox col-12 d-flex mt-4 hstack gap-3"
                            v-if="user.id == issue.user_id && issue.status != 'issued'"
                        >
                            <button class="btn gray w-100" @click="move(issue.id)">
                                <fa class="icon me-2" icon="rotate"></fa> Перенести
                            </button>
                            <button class="btn btn-danger w-100" @click="showDeleteModal">
                                <fa class="icon me-2" icon="trash-can"></fa> Удалить
                            </button>
                        </div>
                    </div>

                    <!--TABS-->
                    <div class="tabsBox col-12 p-0">
                        
                        <ul class="flex-nowrap mb-0 mt-5 nav nav-tabs pb-0" id="myTab" role="tablist">
                            <li class="nav-item w-100 text-center curp" role="presentation" @click="activeTab = 'status'">
                                <a class="nav-link" :class="{ active: activeTab === 'status' }">СТАТУС</a>
                            </li>
                            <li class="nav-item w-100 text-center curp" role="presentation" @click="activeTab = 'history'">
                                <a class="nav-link" :class="{ active: activeTab === 'history' }">ИСТОРИЯ</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div :class="{ 'show active': activeTab === 'status' }" class="tab-pane fade">
                                <div class="statusBox col-12 py-3">
                                    <!--Ресепшн-->
                                    <div class="statusLine wait align-items-center d-flex flex-wrap no-gutters" v-if="!issue.ch_reception">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">Ресепшн</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласование</span>
                                    </span>
                                    </div>
                                    <div class="statusLine success align-items-center d-flex flex-wrap no-gutters curp" v-if="issue.ch_reception" @click="openBlock('reception')">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-success" icon="user-check"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">Ресепшн</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласовано</span>
                                    </span>

                                        <div class="status_description_wrap col-12" v-if="openedBlock === 'reception'">
                                            <div class="status_row d-flex flex-wrap justify-content-between status_row">
                                                <div class="status_row_col1">
                                                    Оформление и подарки подготовлены
                                                </div>
                                                <div class="status_row_col2"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--СБ доки-->
                                    <div class="statusLine wait align-items-center d-flex flex-wrap no-gutters" v-if="issue.ch_sb == 'in_work'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">СБ - сверка доукомплектации</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласование</span>
                                    </span>
                                    </div>

                                    <div class="statusLine stop align-items-center d-flex flex-wrap no-gutters" v-if="issue.ch_sb == 'rejection'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-danger" icon="user-clock"></fa>
                                    </span>
                                    <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">СБ - сверка доукомплектации</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">Отказано</span>
                                    </span>
                                    <span class="fs-2 col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left" @click="showModal(issue.id, 'ch_sb')">
                                        <fa class="icon pe-1 pt-1 text-danger" icon="rotate"></fa>
                                    </span>
                                    </div>

                                    <div class="statusLine success align-items-center d-flex flex-wrap no-gutters curp" v-if="issue.ch_sb == 'agreed'" @click="openBlock('sb')">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-success" icon="user-check"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">СБ - сверка доукомплектации</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласовано</span>
                                    </span>

                                        <div class="status_description_wrap col-12" v-if="openedBlock === 'sb'">
                                            <div class="status_row d-flex flex-wrap justify-content-between status_row" v-if="!issue.additions.length">
                                                <div class="status_row_col1 font-weight-bold">
                                                    Список аксессуаров и услуг пуст
                                                </div>
                                            </div>

                                            <template v-if="issue.additions.length">
                                                <div class="status_row d-flex flex-wrap justify-content-between status_row" v-for="add in issue.additions" :key="add.id">
                                                    <div class="status_row_col1">
                                                        {{add.name}} - <strong>{{add.count}}</strong>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>

                                    <!--ФИС-->
                                    <div class="statusLine wait align-items-center d-flex flex-wrap no-gutters" v-if="!issue.ch_credit">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">Отдел кредитования</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласование</span>
                                    </span>
                                    </div>

                                    <div class="statusLine success align-items-center d-flex flex-wrap no-gutters curp" v-if="issue.ch_credit" @click="openBlock('credit')">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-success" icon="user-check"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">Отдел кредитования</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласовано</span>
                                    </span>

                                        <div class="status_description_wrap col-12" v-if="openedBlock === 'credit'">
                                            <div class="status_row d-flex flex-wrap justify-content-between status_row">
                                                <template v-if="issue.info.credit_data > 0">
                                                    <div class="status_row_col1">
                                                        Оформлен кредит на сумму
                                                    </div>
                                                    <div class="status_row_col2">
                                                        {{ issue.info.credit_data_format }} тг.
                                                    </div>
                                                </template>
                                                <template v-if="issue.info.credit_data === 0">
                                                    <div class="status_row_col1">Кредит не оформлялся</div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Касса-->
                                    <div class="statusLine wait align-items-center d-flex flex-wrap no-gutters" v-if="issue.ch_cash == 'in_work'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">Касса</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласование</span>
                                    </span>
                                    </div>

                                    <div class="statusLine stop align-items-center d-flex flex-wrap no-gutters" v-if="issue.ch_cash == 'rejection'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-danger" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">Касса</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">отказ</span>
                                    </span>
                                    </div>

                                    <div class="statusLine success align-items-center d-flex flex-wrap no-gutters curp" v-if="issue.ch_cash == 'agreed'" @click="openBlock('cash')">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-success" icon="user-check"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">Касса</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласовано</span>
                                    </span>

                                        <div class="status_description_wrap col-12" v-if="openedBlock === 'cash'">
                                            <div class="status_row d-flex flex-wrap justify-content-between status_row">
                                                <div class="status_row_col1">Касса по 1С</div>
                                                <div class="status_row_col2">
                                                    {{ issue.info.price_in_cash_format }} тг.
                                                </div>
                                            </div>
                                            <div class="status_row d-flex flex-wrap justify-content-between status_row">
                                                <div class="status_row_col1">Стоимость АСП</div>
                                                <div class="status_row_col2">
                                                    {{
                                                        issue.info.vin_sec === null
                                                            ? "без АСП"
                                                            : issue.info.price_sec_format + " тг."
                                                    }}
                                                </div>
                                            </div>
                                            <div class="status_row d-flex flex-wrap justify-content-between status_row">
                                                <div class="status_row_col1">Кредит</div>
                                                <div class="status_row_col2">
                                                    {{
                                                        issue.info.credit_data === 0
                                                            ? "не в кредит"
                                                            : issue.info.credit_data_format + " тг."
                                                    }}
                                                </div>
                                            </div>
                                            <div class="status_row d-flex flex-wrap justify-content-between status_row">
                                                <div class="status_row_col1">Договор</div>
                                                <div class="status_row_col2">
                                                    {{ issue.info.price_format }} тг.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--РОП стандарты-->
                                    <div class="statusLine wait align-items-center d-flex flex-wrap no-gutters" v-if="issue.ch_chief == 'in_work'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">{{
                                                issue.chief_name_short + " - стандарты выдачи"
                                            }}</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласование</span>
                                    </span>
                                    </div>

                                    <div class="statusLine stop align-items-center d-flex flex-wrap no-gutters" v-if="issue.ch_chief == 'rejection'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-danger" icon="user-clock"></fa>
                                    </span>
                                    <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">{{
                                                issue.chief_name_short + " - стандарты выдачи"
                                            }}</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">Отказано</span>
                                    </span>
                                    <span class="fs-2 col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left" @click="showModal(issue.id, 'ch_chief')"
                                        v-if="issue.is_manager"
                                    >
                                        <fa class="icon pe-1 pt-1 text-danger" icon="rotate"></fa>
                                    </span>
                                    </div>

                                    <div class="statusLine success align-items-center d-flex flex-wrap no-gutters curp" v-if="issue.ch_chief == 'agreed'" @click="openBlock('rop')">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-success" icon="user-check"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">{{
                                                issue.chief_name_short + " - стандарты выдачи"
                                            }}</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласовано</span>
                                    </span>

                                        <div class="status_description_wrap col-12" v-if="openedBlock === 'rop'">
                                            <template v-for="check in issue.manager_checklist.data">
                                                <div class="status_row d-flex flex-wrap justify-content-between status_row position-relative" :key="check" v-if="check.done">
                                                    <div class="status_row_col1 d-flex flex-wrap align-items-center pl-4">
                                                        <fa class="icon pe-1 pt-1 text-black-50" icon="circle-check"></fa> {{check.text}}
                                                    </div>
                                                </div>

                                                <div class="status_row d-flex flex-wrap justify-content-between status_row position-relative" :key="check" v-if="!check.done">
                                                    <div class="status_row_col1 d-flex flex-wrap align-items-center pl-4">
                                                        <fa class="icon pe-1 pt-1 text-black-50" icon="circle"></fa> {{check.text}}
                                                    </div>
                                                </div>
                                            </template>

                                        </div>
                                    </div>

                                    <!--РОП выезд-->
                                    <div class="statusLine wait align-items-center d-flex flex-wrap no-gutters" v-if="issue.access_chief == 'in_work'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">{{
                                                issue.chief_name_short + " - разрешение на выдачу"
                                            }}</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласование</span>
                                    </span>
                                    </div>

                                    <div class="statusLine stop align-items-center d-flex flex-wrap no-gutters" v-if="issue.access_chief == 'rejection'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-danger" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">{{
                                                issue.chief_name_short + " - разрешение на выдачу"
                                            }}</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">Отказано</span>
                                    </span>
                                        <span class="fs-2 col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left" @click="showModal(issue.id, 'access_chief')">
                                        <fa class="icon pe-1 pt-1 text-danger" icon="rotate"></fa>
                                    </span>
                                    </div>

                                    <div class="statusLine success align-items-center d-flex flex-wrap no-gutters" v-if="issue.access_chief == 'agreed'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-success" icon="user-check"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">{{
                                                issue.chief_name_short + " - разрешение на выдачу"
                                            }}</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласовано</span>
                                    </span>
                                    </div>

                                    <!--СБ выезд-->
                                    <div class="statusLine wait align-items-center d-flex flex-wrap no-gutters" v-if="issue.access_sb == 'in_work'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-black-50" icon="user-clock"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">СБ - разрешение на выезд</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласование</span>
                                    </span>
                                    </div>

                                    <div class="statusLine success align-items-center d-flex flex-wrap no-gutters" v-if="issue.access_sb == 'agreed'">
                                    <span class="col-1 mr-3 mr-md-0 post_status_icon text-md-center text-right text-xl-left">
                                        <fa class="icon pe-1 pt-1 text-success" icon="user-check"></fa>
                                    </span>
                                        <span class="col-10 col-md-11 d-flex flex-wrap ml-xl-n5 no-gutters post_name_status">
                                        <span class="col-12 col-sm-8 post_name">СБ - разрешение на выезд</span>
                                        <span class="col-12 col-sm-4 post_status pt-1 text-sm-right">согласовано</span>
                                    </span>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- History -->
                            <div :class="{ 'show active': activeTab === 'history' }" class="tab-pane fade">
                                <div class="historyWrap">
                                    <div class="history">
                                        <div class="history_header d-flex col-12">
                                            <div class="time d-none d-lg-block col-md-3">Время</div>
                                            <div class="action d-none d-lg-block col-md-3">Должность</div>
                                            <div class="status d-none d-lg-block col-md-3">Статус</div>
                                            <div class="deparment d-none d-lg-block col-md-3">Сотрудник</div>
                                        </div>

                                        <div class="history_row" v-for="log in issue.logs" :class="{ green: !log.type, red: log.type }" :key="log.id">
                                            
                                            <div class="time col-12 col-md-2 col-lg-3 vstack d-flex no-gutters align-content-center">
                                                <span class="hm col-12 col-lg-3 col-xl-2">{{log.b_time[0]}}</span>
                                                <span class="dmy col-12 small col-lg-6">{{log.b_time[1]}}</span>
                                            </div>
                                            
                                            <div class="action col-12 col-sm-4 col-md-3">
                                                {{ log.post }}
                                            </div>

                                            <div class="status col-12 col-sm-4 col-md-3">
                                                {{ log.status }}
                                                <div class="comment col-12" v-if="log.comment && log.comment.length">
                                                    {{ log.comment }}
                                                </div>

                                                <div v-if="log.audio">
                                                    <voice :log="log" />
                                                </div>
                                                
                                                <div v-if="log.photo">
                                                    <photo :log="log" />
                                                </div>
                                                
                                            </div>
                                            <div class="deparment col-12 col-sm-4 col-md-4 col-lg-3">
                                                {{ log.user }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <transition-group name="list">
        <div class="modal d-block" v-if="is_modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close close_modal" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3 mt-3">
                            <label class="col-sm-12 col-form-label required text-start">Комментарий</label>
                            <div class="col-sm position-relative">
                                <textarea class="form-control" rows="3" v-model="comment"></textarea>
                            </div>
                        </div>
                        <div class="col-sm position-relative mb-2 mt-2">
                            <button class="btn btn_vw btn-red"
                                    @click="update(issue.id)"
                            >
                                Отправить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition-group>

    <transition-group name="list">
        <div class="modal d-block" v-if="is_delete_modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Вы действительно хотите удалить выдачу?</h5>
                        <button type="button" class="btn-close close_modal" @click="closeDeleteModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="check_btns col-12 hstack justify-content-between my-3">
                            <div class="btn btn-red col-5 col-lg-3 col-md-4"
                                 @click="closeDeleteModal"
                            >НЕТ</div>
                            <div class="btn btn-green col-5 col-lg-3 col-md-4"
                                 @click="remove(issue.id)"
                            >ДА</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition-group>
</template>

<script>
import axios from "axios";

export default {
    name: "EditModal",
    props: {
        issue: Object,
        user: null,
    },
    data() {
        return {
            is_modal: false,
            is_delete_modal: false,
            comment: null,
            type: null,
            activeTab: "status",
            positions: [
                ["09:00", "10:30"],
                ["10:30", "12:00"],
                ["12:00", "13:30"],
                ["13:30", "15:00"],
                ["15:00", "16:30"],
                ["16:30", "18:00"],
                ["18:00", "19:30"],
                ["19:30", "21:00"],
            ],
            openedBlock: null,
        };
    },
    methods: {
        closeModal(){
            this.is_modal = false;
        },
        closeDeleteModal(){
            this.is_delete_modal = false;
        },
        showModal(id, type){
            this.type = type;
            this.is_modal = true;
        },
        showDeleteModal(){
            this.is_delete_modal = true;
        },
        remove(id) {
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.delete(`/api/admin/issuances/${id}`, config)
                .then(response => {
                    if(response.status == 200){
                        this.is_alert = true;
                        this.message = 'Успешно удалено';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.updateCheckList();
                        this.closeDeleteModal();
                    }
                })
                .catch(errors => {
                    this.isLoading = false;
                    console.log(errors);
                });
        },
        move(id){
            this.$emit('move', { id: id })
        },
        updateCheckList(){
            this.$emit('update-check-list')
        },
        update(id){
            this.closeModal();
            this.$emit('update-check', {id: id, comment: this.comment, type:this.type})
            this.comment = '';
            this.type = '';
        },
        close() {
            this.$emit('close-edit-modal')
        },
        openBlock(block) {
            this.openedBlock = this.openedBlock === block ? null : block;
        },
    },
}
</script>

<style scoped>

</style>
