<template>
    <spinner v-if="isLoading"></spinner>
    <transition name="fade">
        <div>
            <BreadCrumbs
                :breadCrumbs="breadCrumbs"
                :title="title"
                :back_title="back_title"
                :link="link"
            />
            <div class="page">
                <div class="d-flex justify-content-around gap-3 flex-wrap" v-if="this.lists.length > 0">
                    <h5 class="ps-3 mb-3"><fa icon="list-check" class="icon" style="padding-right: 10px; color: #c00;"></fa>Список выдач</h5>

                    <div  class="col-12 item_title mb-3"
                          v-for="list in lists"
                          :key="list.id"
                    >
                        <h5 class="text-center mb-3">Договор № {{ list.info.contract_num }}</h5>
                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="car"></fa>
                                    {{ list.info.car }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <span>VIN:</span>
                                    {{ list.info.vin }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="user"></fa>
                                    {{ list.info.client }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-1">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="user-tie"></fa>
                                    {{ list.user.surname }} {{ list.user.name }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="calendar"></fa>
                                    {{ list.formatDate }}
                                </div>
                            </div>
                        </div>

                        <div class="gn_row d-flex justify-content-between align-items-center mb-2">
                            <div class="d-flex flex-column">
                                <div class="gn_worker_name text-start">
                                    <fa class="icon pe-1 text-black-50" icon="clock"></fa>
                                    {{ list.time_period[0] }} -
                                    {{ list.time_period[1] }}
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-red btn_vw mt-2 mb-2"
                                v-if="list.scheduled && !list.chief_sended"
                                @click="workOut(list.id)"
                        >Проверить
                        </button>

                        <button class="btn btn-green btn_vw mt-2 mb-2"
                                v-if="list.chief_sended && list.access_chief == 'agreed' && list.access_sb != 'agreed'"
                                @click="allowSb(list.id)"
                        >Разрешить выезд
                        </button>

                    </div>
                </div>
                <div class="text_end" v-else>
                    <div class="item_title text-center border-green mb-4">
                        Все выдачи оформлены
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import spinner from "../../components/Spinner";
import {ErrorMessage, Field, Form} from "vee-validate";
import VueMultiselect from "vue-multiselect";
import BreadCrumbs from "../../Page/BreadCrumbs";
import ToastAlert from "../../components/ToastAlert";
import CreateModal from "./CreateModal";
import axios from "axios";

export default {
    name: "CheckListSb",
    components: {
        spinner, Field, Form, ErrorMessage, VueMultiselect, BreadCrumbs, ToastAlert, CreateModal
    },
    data(){
        return {
            lists: [],
            message: '',
            bg: 'bg-primary',
            is_alert: false,
            isLoading: false,
            breadCrumbs: true,
            title: 'Список выдач',
            back_title: 'Меню выдачи',
            link: 'issuance',
        }
    },
    created() {
        this.getDate();
    },
    methods: {
        workOut(id){
            this.$router.push(`/issuance/sb/checklist/${id}`)
        },
        allowSb(id){
            const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
            axios.post('/api/admin/issuances/sb/checklist/allow', {
                issue_id: id,
            }, config)
                .then(response => {
                    if (response.status == 200) {
                        this.bg = 'bg-success'
                        this.is_alert = true;
                        this.message = 'Выдача разрешена!';
                        setTimeout(() => {this.closeAlert(); }, 3000);
                        this.getDate();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async getDate() {
            try {
                this.isLoading = true;
                const config = {'headers': { 'Authorization': 'Bearer ' + this.$cookie.getCookie('token') }};
                const response = await axios.get(`/api/admin/issuances/sb/checklist`, config);
                this.lists = response.data.data.lists;
            } catch (e) {
                console.log(e);
            }finally {
                this.isLoading = false;
            }
        },
    }
}
</script>

<style scoped>

</style>
